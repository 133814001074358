.favorites-section-skeleton-edit-link-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.favorites-section-skeleton-edit-link {
  width: 2.375rem;
  height: 1.05rem;
  border-radius: 5px;
}

.favorites-section-skeleton-favorites-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
}

@media screen and (min-width: 768px) {
  .favorites-section-skeleton-favorites-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .favorites-section-skeleton-favorites-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1550px) {
  .favorites-section-skeleton-favorites-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
