.sk-cart-bar {
  background: radial-gradient(
    30px 30px at 50% 145%,
    transparent 42px,
    var(--skeleton-secondary-color) 38.5px
  );
  width: calc(100% - 12px);
  border-radius: 9px;
  margin: 0 auto 33px auto;
  border: none;
  height: 60px;
  max-width: var(--max-container-width);
  width: calc(var(--container-width) - 4%);
}

/* @media screen and (min-width: 850px) {
  .sk-cart-bar {
    width: 550px;  }
} */

@media screen and (min-width: 1024px) {
  .sk-cart-bar {
    display: none;
  }
}