.sk-image-header {
  position: relative;
  background-color: var(--skeleton-terniary-color);
  width: 100%;
  height: 208px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  justify-content: center;
  max-width: var(--max-wrapper-width);
  margin: 0 auto;
}

.sk-image-header.replace {
  border-radius: 0px 0px 50px 50px;
}

@media screen and (max-width: 767px) {
  .sk-image-header.replace {
    height: 13rem;
  }
}

@media screen and (min-width: 768px) {
  .sk-image-header {
    height: 400px;
  }
}

.sk-image-header-title-container{
  display: flex;
  align-items: center;
  max-width: var(--max-container-width);
  position: absolute;
  width: 85%;
  bottom: 2.3rem;
}

.sk-image-header-logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 11px;
  margin-right: 1rem;
}

@media screen and (min-width: 1024px) {
  .sk-image-header-logo {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (min-width: 1150px) {
  .sk-image-header-title-container {
    width: 100%;
  }
}

body[dir="rtl"] .sk-image-header-logo {
  margin-right: unset;
  margin-left: 1rem;
}

.sk-header-resto-name {
  width: 10rem;
  height: 1.5rem;
  margin-bottom: 10px;
  border-radius: 3px;
}

.sk-header-container {
  display: flex;
  flex-direction: column;
}

.sk-header-change-resto {
  height: 0.8rem;
  width: 5rem;
  border-radius: 3px;
}

body[dir="rtl"] .sk-header-resto-name {
  margin-right: unset;
  margin-left: 1rem;
}