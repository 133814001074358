.sk-popular-item {
  width: 5rem;
  height: 4.375rem;
  margin: 0 5px;
  background-color: var(--skeleton-secondary-color);
  background-color: pink;
  border-radius: 7px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.sk-popular-item .sk-popular-item-title {
  width: 100%;
  height: 0.5em;
  background-color: var(--skeleton-primary-color);
  margin: 0 5px 5px 5px;
}

@media screen and (min-width: 767px) {
  .sk-popular-item {
    width: 10rem;
    height: 9.3rem;
    align-items: center;
    margin-left: 1.5rem;
  }
  .sk-popular-item .sk-popular-item-title {
    width: 95%;
    height: 1em;
  }
}
