.address-info-section-skeleton-container {
  margin: 0 auto;
}

.address-info-section-skeleton-map-section {
  width: 100%;
}

.address-info-section-skeleton-map-title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.address-info-section-skeleton-map-title {
  width: 7.6875rem;
  height: 1rem;
  border-radius: 5px;
}

.address-info-section-skeleton-refine-link {
  width: 6.125rem;
  height: 1rem;
  border-radius: 5px;
}

.address-info-section-skeleton-map {
  border-radius: 22px;
  height: 6.125rem;
  width: 100%;
  background-color: var(--skeleton-secondary-color);
}

.address-info-section-skeleton-info-form {
  margin-top: 55px;
  position: relative;
  width: 100%;
}

.address-info-section-skeleton-input {
  width: 100%;
  height: 3.5rem;
  border-radius: 7px;
  margin: 36px 0;
}

.address-info-section-skeleton-directions {
  width: 100%;
  border-radius: 7px;
  height: 7.5rem;
}

.address-info-section-skeleton-confirm-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.2rem;
}

.address-info-section-skeleton-confirm-btn {
  border-radius: 12px;
  height: 56px;
  width: 333px;
  max-width: 100%;
}
