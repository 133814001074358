.options-message-popup-page-wrapper {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


.options-message-popup-container {
  width: 90%;
  max-width: 500px;
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  border-radius: 15px;
  padding: 1.5rem;
  overflow: auto;
  font: var(--font-regular);  
}

.options-message-popup-texts-container {
    display: flex;
}

.options-message-popup-texts {
    display: flex;
    flex-direction: column;
}

.options-message-popup-container .options-message-popup-icon {
    margin-right: 10px;
    align-self: flex-start;
}

.options-message-popup-title {
font: var(--font-bold);
font-size: var(--font-seventeen);
margin-bottom: .6rem;
}

.options-message-popup-text {
    margin: 1rem 0;
}

.options-message-popup-btns {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.options-message-popup-btns button {
    font: var(--font-semibold);
    font-size: var(--font-fourteen);
    padding:7px;
    min-width: 75px;
}

.options-message-popup-btn-option-one {
    color:white;
    border-radius: 5px;
    margin-right: 12px;
}

.options-message-popup-btn-option-two {
    background-color: var(--warning-color);
    color:white;
    border-radius: 5px;
    margin-right: 8px;
}

.options-message-popup-btn-option-two-normal {
    border-radius: 5px;
    margin-right: 8px;
    background-color: var(--light-text-color-over-secondary-color);
    color: var(--text-color-over-main-color);
}

.options-message-popup-btn-no {
    background-color: transparent;
    color:var(--main-color);
}

/* info styles */
.options-message-popup-container.info {
    border-left: 0.5rem solid var(--main-color);
}

.options-message-popup-container.info .options-message-popup-icon {
    color: var(--main-color);
}

.options-message-popup-container.info .options-message-popup-btn-option-one {
    background-color: var(--main-color);
    color: var(--text-color-over-main-color);
}

/* warning styles */
.options-message-popup-container.warning {
    border-left: 0.5rem solid var(--waiting-color);
}

.options-message-popup-container.warning .options-message-popup-icon {
    color: var(--waiting-color);
}

.options-message-popup-container.warning .options-message-popup-btn-option-one {
    background-color: var(--waiting-color);
}

/* error styles */
.options-message-popup-container.error {
    border-left: 0.5rem solid var(--warning-color);
}

.options-message-popup-container.error .options-message-popup-icon {
    color: var(--warning-color);
}

.options-message-popup-container.error .options-message-popup-btn-option-one {
    background-color: var(--warning-color);
}

/* rtl changes */
body[dir="rtl"] .options-message-popup-container.info {
    border-left: unset;
    border-right: 0.5rem solid var(--main-color);
}

body[dir="rtl"] .options-message-popup-container.warning {
    border-left: unset;
    border-right: 0.5rem solid var(--waiting-color);
}

body[dir="rtl"] .options-message-popup-container.error {
    border-left: unset;
    border-right: 0.5rem solid var(--error-color);
}

body[dir="rtl"] .options-message-popup-container .options-message-popup-icon {
    margin-right: unset;
    margin-left: 10px;
}

.options-message-popup-btn-option-one:focus,
.options-message-popup-btn-option-two:focus,
.options-message-popup-btn-option-two-normal:focus,
.options-message-popup-btn-no:focus {
  outline-offset: 2px;
}

.options-message-popup-container.info .options-message-popup-btn-option-one:focus,
.options-message-popup-container.info .options-message-popup-btn-option-two:focus,
.options-message-popup-container.info .options-message-popup-btn-option-two-normal:focus,
.options-message-popup-container.info .options-message-popup-btn-no:focus {
  outline: 2px solid var(--main-color); /* Add a clear focus outline */
  outline-offset: 2px;
}

.options-message-popup-container.warning .options-message-popup-btn-option-one:focus,
.options-message-popup-container.warning .options-message-popup-btn-option-two:focus,
.options-message-popup-container.warning .options-message-popup-btn-option-two-normal:focus,
.options-message-popup-container.warning .options-message-popup-btn-no:focus {
  outline: 2px solid var(--waiting-color); /* Add a clear focus outline */
  outline-offset: 2px;
}

.options-message-popup-container.error .options-message-popup-btn-option-one:focus,
.options-message-popup-container.error .options-message-popup-btn-option-two:focus,
.options-message-popup-container.error .options-message-popup-btn-option-two-normal:focus,
.options-message-popup-container.error .options-message-popup-btn-no:focus {
  outline: 2px solid var(--warning-color); /* Add a clear focus outline */
  outline-offset: 2px;
}

