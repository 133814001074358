.resto-skeleton-card-container {
    margin-bottom: 2.4rem;
    width: calc(100%);
    max-width: 400px;
}

.resto-skeleton-card-container .card-image {
    border-radius: 15px;
    width: 100%;
    height: 220px;
}

.resto-skeleton-card-container .resto-skeleton-card-name {
    width: 84px;
    height: 15px;
    border-radius: 3px;
    margin-top: 5px;
}

.resto-skeleton-card-container .resto-skeleton-card-desc {
    width: 107px;
    height: 15px;
    border-radius: 3px;
    margin-top: 5px;
}