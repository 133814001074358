.news-sk-events-container {
  width: 100%;
  margin-top: 3rem; 
  padding-bottom: 140px;
}

.news-sk-events-container.grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  align-items: stretch;
}

@media screen and (min-width: 320px) {
.news-sk-events-container.grid {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}

@media screen and (min-width: 525px) {
.news-sk-events-container.grid {
    grid-template-columns: repeat(3, minmax(120px, 1fr));
  }
}

@media screen and (min-width: 700px) {
.news-sk-events-container.grid {
    grid-template-columns: repeat(3, minmax(120px, 1fr));
  }
  .events-no-events-btns-container button {
    width: 4.8rem;
    height: 2rem;
  }
}

@media screen and (min-width: 960px) {
.news-sk-events-container.grid {
    grid-template-columns: repeat(4, minmax(120px, 1fr));
    gap: 1rem;
  }
}

@media screen and (min-width: 1400px) {
.news-sk-events-container.grid {
    grid-template-columns: repeat(5, minmax(120px, 1fr));
    gap: 1rem;
  }
}

.news-sk-events-container.list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
