.sk-home-section-one-wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: var(--container-width);
  max-width: var(--max-container-width);
  margin-left: auto;
  margin-right: auto;
}

.sk-home-section-one-wrapper .bar {
  background-color: var(--skeleton-secondary-color);
  border-radius: 3px;
}

.sk-home-section-one-wrapper .resto-name {
  width: 10.625em;
  height: 1.375em;
  margin-top: 2.5rem;
}

.sk-home-section-one-wrapper .main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sk-home-section-one-wrapper .main-content .section-one-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1.1rem 0 1.3rem;
}

.sk-home-section-one-wrapper .main-content .section-one-wrapper .gmorning {
  width: 8rem;
  height: 1rem;
  margin-bottom: 0.4rem;
  position: relative;
}

.sk-home-section-one-wrapper
  .main-content
  .section-one-wrapper
  .gold-points-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  margin-top: -8px;
}

.sk-home-section-one-wrapper .main-content .section-one-wrapper .language-selection-icon {
  border-radius: 7px;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.sk-home-section-one-wrapper .main-content .section-one-wrapper .theme-switch {
  width: 4.375rem;
  height: 2rem;
  border-radius: 20px;
}

body[dir="rtl"] .sk-home-section-one-wrapper .main-content .section-one-wrapper .language-selection-icon {
  margin-left: unset;
  margin-right: 10px;
}

.sk-home-section-one-wrapper .main-content .section-two-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.3rem;
  width: 100%;
}

.sk-home-section-one-wrapper
  .main-content
  .section-two-wrapper
  .current-location {
  width: 10.75em;
  height: 0.8125em;
  height: 0.9em;
  margin-right: 5px;
}

body[dir="rtl"] .sk-home-section-one-wrapper
  .main-content
  .section-two-wrapper
  .current-location {
    margin-right: unset;
    margin-left: 5px;
}

.sk-home-section-one-wrapper
  .main-content
  .section-two-wrapper
  .delivery-method-btns {
  width: calc(100% - 11.75em);
  max-width: 9.3125em;
  max-width: 12em;
  height: 1.8125em;
}

.sk-home-section-one-wrapper .min-charge {
  width: 11.5em;
  height: 0.8125em;
  margin-top: 0.35em;
}

@media screen and (min-width: 350px) {
  .sk-home-section-one-wrapper .main-content .section-one-wrapper .gmorning {
    width: 11rem;
  }
}

@media screen and (min-width: 1350px) {
  .sk-home-section-one-wrapper .main-content .section-one-wrapper {
    margin-top: 2.2rem;
  }

  .sk-home-section-one-wrapper .main-content .section-one-wrapper .language-selection-icon {
    margin-top: -4.5px;
  }

  .sk-home-section-one-wrapper .main-content .section-one-wrapper .gmorning {
    margin-bottom: 0.85rem;
  }

  .sk-home-section-one-wrapper .section-two-wrapper {
    width: 50% !important;
  }
  .sk-home-section-one-wrapper .main-content {
    flex-direction: row;
    justify-content: space-between;
    
  }

  .sk-home-section-one-wrapper .main-content .section-two-wrapper {
    justify-content: flex-end;
    margin-top: 1.8rem;
  }
  
  /* .sk-home-section-one-wrapper .section-one-wrapper .gold-points-container {
    position: absolute;
    left: calc(50% - 250px);
  } */
    .sk-home-section-one-wrapper .section-one-wrapper {
      width: 35%;
  }
}
