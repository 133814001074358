.recent-reservation-sk-wrapper {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
}

.recent-reservation-sk-container {
    width: 85%;
    max-width: var(--max-container-width);
}

.recent-reservation-sk-top-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recent-reservation-sk-title, .recent-reservation-sk-edit {
    border-radius: 5px;
    height: 0.8rem;
}

.recent-reservation-sk-title {
    width: 3.375rem;
}

.recent-reservation-sk-edit {
    width: 2.4375rem;
}

.recent-reservation-sk-main {
  background-color: var(--skeleton-secondary-color);
  width: 85%;
  max-width: var(--max-width-container);
  height: 2.72rem;
  border-radius: 15px;
  margin-top: 1rem;
}

.recent-reservation-sk-remind-me-btn {
width: 6.375rem;
height: 1.75rem;
border-radius: 12px;
margin-top: 1rem;
}