.restos-header-skeleton-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.restos-header-skeleton-container .header-picture {
    width: 14.5rem;
    height: 9.5rem;
    border-radius: 3px;
}

.restos-header-skeleton-container .header-title-up {
    width: 233px;
    height: 21px;
    border: 3px;
    margin-top: 0.7rem;
}

.restos-header-skeleton-container .header-title-down {
    width: 120px;
    height: 21px;
    border: 3px;
    margin-top: 0.5rem;
}