.sk-popular-items-wrapper {
  width: var(--container-width);
  max-width: var(--max-container-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  /* margin-top: 1rem; */
  position: relative;
}

.sk-popular-items-wrapper.alone .pitems-container {
  margin-bottom: 2.3rem;
  margin-bottom: 4vh;
}

.sk-popular-items-wrapper .title {
  width: 7.4375em;
  height: 0.9375em;
  background-color: var(--skeleton-secondary-color);
  margin: 0.5rem 0 1rem 0;
}

.sk-popular-items-wrapper .sk-items-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sk-popular-items-wrapper .pitems-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: nowrap;
  margin-bottom: 2.3rem;
}

@media screen and (min-width: 767px) {
  .sk-popular-items-wrapper {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* width: 90%;
    margin-left: auto;
    margin-right: auto; */
  }
  /* .sk-popular-items-wrapper .pitems-container {
    justify-content: center;
  } */
}
