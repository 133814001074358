.delivery-address-card-skeleton {
  width: 100%;
  background-color: var(--skeleton-secondary-color);
  min-height: 4.82rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 10px;
}

.delivery-address-card-skeleton-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delivery-address-card-skeleton-header-left {
  display: flex;
  align-items: center;
  width: 80%;
}

.delivery-address-card-skeleton-header .label {
  width: 3rem;
  height: 1rem;
  border-radius: 10px;
}

.delivery-address-card-skeleton-header .title {
  height: 1rem;
  border-radius: 5px;
  width: 50%;
  margin-left: 15px;
}

body[dir="rtl"] .delivery-address-card-skeleton-header .title {
  margin-left: unset;
  margin-right: 15px;
}

.delivery-address-card-skeleton-header .edit {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 5px;
}

.delivery-address-card-skeleton-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.delivery-address-card-skeleton-content div {
  height: 0.6rem;
  border-radius: 5px;
}

.delivery-address-card-skeleton-content-line-one {
  width: 100%;
  margin-bottom: 0.4rem;
}

.delivery-address-card-skeleton-content-line-two {
  margin-bottom: 0.4rem;
}

.delivery-address-card-skeleton-content-line-three {
  width: 40%;
}
