.sk-litem-container {
  width: 100%;
  height: 130px;
  border-radius: 10px;
  background-color: var(--skeleton-secondary-color);
  display: grid;
  grid-template-columns: 1.4fr 3fr;
}

.sk-litem-container .sk-litem-image {
  background-color: var(--skeleton-terniary-color);
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  border-radius: 10px;
}

.sk-litem-details {
  padding: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.sk-litem-details > div {
  background-color: var(--skeleton-primary-color);
  border-radius: 3px;
}
.sk-litem-details .sk-litem-title {
  width: 5.1875em;
  height: 0.95em;
}

.sk-litem-details .sk-litem-desc-line-one {
  width: 13.125em;
  height: 0.8125em;
}

.sk-litem-details .sk-litem-desc-line-two {
  width: 9.875em;
  height: 0.8125em;
}

.sk-litem-details .sk-litem-bottom-details {
  align-self: flex-end;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sk-litem-details .sk-litem-bottom-details .sk-litem-icons {
  width: 1.15em;
  height: 1.1em;
  background-color: var(--skeleton-primary-color);
}

.sk-litem-details .sk-litem-bottom-details .sk-litem-price {
  width: 2.5625em;
  height: 0.8125em;
  background-color: var(--skeleton-primary-color);
}
@media screen and (min-width: 767px) {
  .sk-litem-container {
    height: 170px;
  }
}
