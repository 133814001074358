.sk-header-top-nav {
  background-color: var(--skeleton-secondary-color);
  width: 85%;
  max-width: var(--max-container-width);
  position: absolute;
  height: 2.72rem;
  bottom: -1.2rem;
  border-radius: 15px;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .sk-header-top-nav {
    height: 3.2rem;
    bottom: -1.4rem;
  }
}

@media screen and (min-width: 1150px) {
  .sk-header-top-nav { 
    width: 100%;
   }
}