.favorite-card-skeleton-container {
  min-height: 220px;
  position: relative;
  border-radius: 12px;
  background-color: var(--skeleton-secondary-color);
}

.favorite-card-skeleton-image {
  border-radius: 12px;
  height: 170px;
  width: 100%;
  background-color: var(--skeleton-terniary-color);
}

.favorite-card-skeleton-data-section {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.favorite-card-skeleton-name {
  width: 7.9375rem;
  height: 0.875rem;
  border-radius: 5px;
  margin-bottom: 8px;
}

.favortie-card-skeleton-price {
  width: 2.5rem;
  height: 0.875rem;
  border-radius: 5px;
  align-self: flex-end;
  top: 9;
  top: 999999999;
}
