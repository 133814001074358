.sk-top-sticky-container {
  position: sticky;
  top: 0;
  background-color: var(--skeleton-primary-color);
  width: var(--container-width);
  max-width: var(--max-container-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 5px;
  z-index: 100;
}

.sk-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 7px;
  /* margin-top: 2.3rem; */
}

.sk-search-wrapper .sk-search-bar {
  background-color: var(--skeleton-secondary-color);
  border-radius: 30px;
  height: 38px;
  width: calc(100% - 75px);
  margin-right: 10px;
  padding: 5px 10px;
}

body[dir="rtl"] .sk-search-bar {
  margin-right: unset;
  margin-left: 10px;
}

.sk-search-wrapper .sk-view-btns-container {
  display: flex;
  align-items: center;
}

.sk-search-wrapper .sk-view-btns-container .sk-view-btn {
  background-color: var(--skeleton-secondary-color);
  width: 30px;
  height: 22px;
  /* width: 1.9em;
  height: 1.375em; */
  border-radius: 12px;
  margin-left: 5px;
}

.sk-groups-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
}

.sk-groups-wrapper .sk-menu-icon {
  min-width: 22px;
  height: 22px;
  background-color: var(--skeleton-secondary-color);
  border-radius: 7px;
}

.sk-groups-wrapper .sk-groups-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-left: 0.5rem;
}

.sk-groups-wrapper .sk-groups-container .sk-group {
  background-color: var(--skeleton-secondary-color);
  border-radius: 7px;
  height: 2rem;
  margin: 0 7px;
}

.sk-group.large {
  min-width: 5.75em;
}

.sk-group.medium {
  min-width: 4.375em;
}

.sk-group.small {
  min-width: 4em;
}

@media screen and (min-width: 767px) {
  .sk-groups-wrapper .sk-groups-container .sk-group {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1200px) {
  .sk-groups-wrapper .sk-groups-container .sk-group {
    margin: 0 17px;
  }
}
