.sk-home-wrapper {
  width: 100%;
  background-color: var(--skeleton-primary-color);
  font-size: 16px;
  position: relative;
}

.sk-home-content {
  width: 100%;
  min-height: 80vh;
  padding: 0 5px;
}

@media screen and (min-width: 550px) {
  .sk-home-wrapper {
    font-size: 18px;
  }
}

@media screen and (min-width: 767px) {
  .sk-home-wrapper {
    font-size: 22px;
  }
  .sk-home-content {
    margin-left: auto;
    margin-right: auto;
  }
}
