.orders-skeleton-top-btns {
  width: 100%;
  border-radius: 12px;
  height: 2rem;
  margin-bottom: 1.2rem;
}

.orders-skeleton-orders-container {
  display: grid;
  row-gap: 1rem;
  column-gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 750px) {
  .orders-skeleton-orders-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
