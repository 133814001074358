.order-card-skeleton {
  min-height: 150px;
  width: 100%;
  background-color: var(--skeleton-secondary-color);
  border-radius: 10px;
  padding: 0.65rem;
}

@media screen and (min-width: 375px) {
  .order-card-skeleton {
    padding: 0.75rem;
  }
}

.order-card-skeleton-order-number {
  width: 50px;
  height: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.order-card-skeleton-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card-skeleton-top-left {
  display: flex;
  align-items: center;
}

.order-card-skeleton-order-type {
  width: 2.1875rem;
  height: 1.2rem;
  border-radius: 10px;
}

.order-card-skeleton-order-date {
  width: 6.875rem;
  height: 0.8rem;
  border-radius: 5px;
  margin-left: 8px;
}

body[dir="rtl"] .order-card-skeleton-order-date {
  margin-left: unset;
  margin-right: 8px;
}

.order-card-skeleton-order-status {
  width: 5rem;
  height: 0.8rem;
  border-radius: 5px;
}

.order-card-skeleton-content-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.order-card-skeleton-content-one {
  width: 4.75rem;
  height: 0.6rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.order-card-skeleton-content-two {
  width: 7.75rem;
  height: 0.6rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.order-card-skeleton-content-three-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card-skeleton-content-three {
  width: 8.875rem;
  height: 0.6rem;
  border-radius: 5px;
}

.order-card-skeleton-price {
  width: 2.5rem;
  height: 0.6rem;
  border-radius: 5px;
}

.order-card-skeleton-bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card-skeleton.current .order-card-skeleton-bottom-left {
  width: 5.125rem;
  height: 1.5rem;
  border-radius: 12px;
}

.order-card-skeleton.previous .order-card-skeleton-bottom-left {
  width: 5.6875rem;
  height: 0.9rem;
  border-radius: 5px;
}

.order-card-skeleton-bottom-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.order-card-skeleton-bottom-right-top {
  width: 5.5rem;
  height: 0.6rem;
  border-radius: 5px;
}

.order-card-skeleton-bottom-right-bottom {
  width: 2.5rem;
  height: 0.6rem;
  border-radius: 5px;
  margin-top: 0.5rem;
}
