.location-search-skeleton-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-search-skeleton-container {
  background-color: var(--skeleton-secondary-color);
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 0;
  width: 90%;
  max-width: 500px;
  height: 11.875rem;
  padding: 15px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
}

.location-search-skeleton-top {
  border-radius: 12px;
  width: 100%;
  height: 2rem;
}

.location-search-skeleton-middle {
  display: flex;
  align-items: center;
  margin: 1em 0 1.3rem 0;
}

.location-search-skeleton-navigator-icon {
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
}

.location-search-skeleton-current-location-btn {
  width: 8.1875rem;
  height: 1rem;
  border-radius: 5px;
  margin-left: 5px;
}

body[dir="rtl"] .location-search-skeleton-current-location-btn {
  margin-left: unset;
  margin-right: 5px;
}

.location-search-skeleton-bottom {
  width: 100%;
  height: 2.875rem;
  border-radius: 12px;
}
