.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  animation: loading 1.5s infinite;
}

.shimmer-wrapper .shimmer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  /* transform: skewX(-200deg); */
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
