.sk-discount-card-container {
  min-width: 195px;
  height: 110px;
  background-color: var(--skeleton-secondary-color);
  border-radius: 10px;
  margin-right: 8px;
  padding: 8px 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border:1px solid white; */
}

body[dir="rtl"] .sk-discount-card-container {
  margin-right: unset;
  margin-left: 8px;
}

.sk-discount-card-left-section > div {
  border-radius: 3px;
  margin: 3px 0;
}

.sk-discount-card-left-section .section-one {
  width: 3rem;
  height: 0.625rem;
}

.sk-discount-card-left-section .section-two {
  width: 3.875rem;
  height: 0.75rem;
}

.sk-discount-card-left-section .section-three {
  width: 4.3125rem;
  height: 0.5rem;
}

.sk-discount-card-left-section .section-four {
  width: 4.125rem;
  height: 1.25rem;
  border-radius: 10px;
}

.sk-discount-card-left-section .section-five {
  width: 2.625rem;
  height: 0.5rem;
}

.sk-discount-card-left-section .section-six {
  width: 4.3125rem;
  height: 0.5rem;
}

@media screen and (min-width: 767px) {
  .sk-discount-card-container {
    min-width: 225px;
    min-height: 130px;
    margin-right: 16px;
  }

  body[dir="rtl"] .sk-discount-card-container {
    margin-right: unset;
    margin-left: 16px;
  }
}

.sk-discount-card-right-section {
  position: absolute;
  right: 0;
}

.sk-discount-card-right-section {
  width: 70px;
  height: 100%;
  border-radius: 30px 10px 10px 30px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
