.sk-offers-section {
    width: var(--container-width);
    max-width: var(--max-container-width);
    display: flex;
    flex-direction: column;
    margin: 37px auto 15px auto;
    overflow: hidden;
}

.sk-offers-section.loading-alone {
  margin: 10px 0 23px 0;
}

.sk-offers-title {
    width: 10.75rem;
    height: 0.9rem;
    border-radius: 3px;
    margin-bottom: 0.55rem;
}

.sk-offers-container {
  display: flex;
  align-items: center;
  padding: 0.7rem 5px 0.7rem 2px;
  overflow: hidden;
  width: 100%;
}

/* @media screen and (min-width: 600px) {
  .sk-offers-container {
    margin-left: 40px;
  }
  body[dir="rtl"] .sk-offers-container {
    margin-left: unset;
    margin-right: 40px;
  } 
} */

body[dir="rtl"] .sk-offers-container {
   padding: 0.7rem 2px 0.7rem 5px;
}

/* @media screen and (min-width: 767px) {
  .sk-offers-container {
    width: calc(var(--container-width) - 10%);
  }
} */
