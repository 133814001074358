.list-event-sk-wrapper {
    width: 100%;
    background-color: var(--skeleton-secondary-color);
    border-radius: 13px;
    padding: 0.65rem;
    margin-bottom: 1.5rem;
}

.list-event-sk-top-section {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.list-event-sk-img {
  width: 9.45rem;
  height: 6.57rem;
  border-radius: 5px;
}

.list-event-sk-top-right-container {
    width: 100%;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.list-event-sk-titles {
    display: flex;
    flex-direction: column;
}

.list-event-sk-text {
    border-radius: 5px;
}

.list-event-sk-text:nth-child(1) {
    height: 0.9rem;
    width: 90%;
}

.list-event-sk-text:nth-child(2) {
    height: 0.8rem;
    width: 35%;
    margin-top: 0.3rem;
}

.list-event-sk-desc .list-event-sk-text:nth-child(1) {
    margin-bottom: 0.5rem;
    width: 100%;
    height: 0.8rem;
}

.list-event-sk-desc .list-event-sk-text:nth-child(2) {
    margin-bottom: 0.5rem;
    width: 100%;
    height: 0.8rem;
}

.list-event-sk-desc .list-event-sk-text:nth-child(3) {
    width: 70%;
    height: 0.8rem;
}

.list-event-sk-bottom-section {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-event-sk-bottom-section .list-event-sk-text:nth-child(1) {
    height: 0.6rem;
    width: 28%;
}

.list-event-sk-bottom-section .list-event-sk-text:nth-child(2) {
    height: 0.7rem;
    width: 18%;
} 