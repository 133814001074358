.reservation-card-skeleton {
  width: 100%;
  border-radius: 10px;
  padding: 1.1rem 0.85rem;
  position: relative;
  background-color: var(--skeleton-secondary-color);
  min-height: 160px;
}

.reservation-card-skeleton-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.reservation-card-skeleton-top-section div {
  height: 0.6875rem;
  border-radius: 5px;
}

.reservation-card-skeleton-date {
  width: 8rem;
}

.reservation-card-skeleton-status {
  width: 3rem;
}

@media screen and(min-width: 320px) {
  .reservation-card-skeleton-date {
    width: 11.125rem;
  }

  .reservation-card-skeleton-status {
    width: 6.1rem;
  }
}

.reservation-card-skeleton-details div {
  height: 0.6875rem;
  border-radius: 5px;
}

.reservation-card-skeleton-detail-one {
  width: 4.9375rem;
}

.reservation-card-skeleton-detail-two {
  width: 7.9375rem;
  margin: 0.7rem 0;
}

.reservation-card-skeleton-bottom-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.7rem;
}

.reservation-card-skeleton.previous .reservation-card-skeleton-bottom-left-btn {
  width: 5.325rem;
  height: 0.6875rem;
  border-radius: 5px;
}

.reservation-card-skeleton.current .reservation-card-skeleton-bottom-left-btn {
  border-radius: 12px;
  width: 5.125rem;
  height: 1.5rem;
}

.reservation-card-skeleton-bottom-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.reservation-card-skeleton-bottom-right-container div {
  height: 0.6875rem;
  border-radius: 5px;
}

.reservation-card-skeleton-branch {
  width: 5.625rem;
}

.reservation-card-skeleton-details-link {
  width: 2.5rem;
  margin-top: 0.4rem;
}
