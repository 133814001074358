.delivery-addresses-section-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}

.delivery-addresses-section-skeleton-title {
  width: 40%;
  max-width: 200px;
  height: 0.9rem;
  border-radius: 5px;
  margin-bottom: 1.2rem;
}
