.previous-order-main-container.skeleton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 4.375rem);
  padding: 2.2rem 1.25rem 4.5rem;
}
