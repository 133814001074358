.new-address-skeleton-section {
  width: 100%;
  margin-bottom: 2rem;
}

.new-address-skeleton-title {
  width: 70%;
  max-width: 400px;
  height: 0.9rem;
  border-radius: 5px;
}

.new-address-skeleton-link {
  width: 100%;
  height: 5rem;
  background-color: var(--skeleton-terniary-color);
  margin-top: 1.4rem;
  border-radius: 10px;
}
