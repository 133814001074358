.gallery-sk-wrapper {
    background-color: var(--skeleton-primary-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery-sk-gallery-wrapper.padding-top {
    margin-top: 4.8rem;
}

.gallery-sk-gallery-wrapper.add-padding {
    padding-top: 1rem;
}

.gallery-sk-gallery-wrapper {
    width: 85%;
    max-width: var(--max-container-width);
    min-height: 500px;
}

.gallery-sk-albums-titles {
    display: flex;
    align-items: center;
    width: 100%;
}

.gallery-sk-albums-titles > div {
    border-radius: 5px;
    height: 1.125rem;
}

.gallery-sk-album-one {
    width: 6.25rem;
    margin-right: 1rem;
}

.gallery-sk-album-two {
    width: 4.375rem;
    margin-right: 1rem;
}

.gallery-sk-album-three {
    width: 2.8125rem;
}   

body[dir="rtl"] .gallery-sk-album-one, body[dir="rtl"] .gallery-sk-album-two {
    margin-right: unset;
    margin-left: 1rem;
}

.gallery-sk-photos-container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 0.65rem;
  height: fit-content;
  margin: 2rem auto 8rem auto;
}

@media screen and (min-width: 768px) {
    .gallery-sk-photos-container{
        grid-template-columns: repeat(4, 1fr);
    }
}

.gallery-sk-image {
  border-radius: 16px;
  cursor: pointer;
}

.gallery-sk-photos-container.three-columns-square .gallery-sk-image {
  aspect-ratio: 1/1;
}
