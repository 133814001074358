.news-sk-wrapper {
    background-color: var(--skeleton-primary-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news-sk-wrapper .padding-top {
    padding-top: 4.6rem;
}


@media screen and (min-width: 950px) {
    .news-sk-wrapper .padding-top {
    padding-top: 4.2rem;
}
}

.news-sk-events-wrapper {
    /* width: var(--container-width); */
    width: 85%;
    max-width: var(--max-container-width);
    min-height: 500px;
}

.news-sk-events-top-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.news-sk-events-title {
    width: 5.625rem;
    height: 0.8rem;
    border-radius: 5px;
}

.news-sk-events-views {
    display: flex;
    align-items: center;
}

.news-sk-events-view {
    width: 1.5rem;
    height: 1.125rem;
    border-radius: 5px;
}

.news-sk-events-view.left {
    margin-right: 10px;
}

body[dir="rtl"] .news-sk-events-view.left {
    margin-right: unset;
    margin-right: 10px;
}

.news-sk-branches-list-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0 2rem 0;
}

.news-sk-branches-list {
    width: 12rem;
    height: 2.5rem;
    border-radius: 15px;
}