.grid-event-sk-card {
    background-color: var(--skeleton-secondary-color);
    border-radius: 13px;
    padding: 0.65rem;
    margin-bottom: 1.5rem;
}

.grid-event-sk-image {
  width: 100%;
  height: 5.5rem;
  object-fit: cover;
  margin-bottom: 5px;
  border-radius: 5px;
  margin:auto;
}

.grid-event-sk-text {
    border-radius: 5px;
    height: 0.8rem;
}

.grid-event-sk-text:nth-child(1){
    width: 90%;
    margin: 0.5rem 0 0.4rem 0;
}

.grid-event-sk-text:nth-child(2){
    width: 65%;
    margin: 0.4rem 0 1.1rem 0;
}

.grid-event-sk-text:nth-child(3){
    width: 45%;
    margin-bottom: 0.6rem;
}

.grid-event-sk-text:nth-child(4){
    width: 80%;
    margin-bottom: 1.1rem;
}

.grid-event-sk-text:nth-child(5){
    width: 55%;
    margin-bottom: 1.1rem;
    height: 0.7rem;
}

.grid-event-sk-text:nth-child(6){
    width: 45%;
    height: 0.625rem;
    justify-self: flex-end;
}

.grid-event-sk-price-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.2rem;
}

.grid-event-sk-price-container .grid-event-sk-text {
    width: 30%;
}