.sk-item-group-wrapper {
  /* width: var(--container-width); */
  max-width: var(--max-container-width);
  /* background-color: var(--skeleton-primary-color); */
  padding: 0 5px 200px 5px;
  margin-left: auto;
  margin-right: auto;
}

.sk-item-group-wrapper .sk-item-group-title {
  width: 3.75em;
  height: 1.1875em;
  background-color: var(--skeleton-secondary-color);
  border-radius: 3px;
  margin: 1rem 0 1rem 0;
}

.sk-item-group-wrapper .sk-items-container {
  width: 100%;
  display: grid;
}

.sk-item-group-wrapper .sk-items-container.grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 0.7rem;
}

.sk-item-group-wrapper .sk-items-container.list {
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .sk-item-group-wrapper .sk-items-container.grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.9em;
  }
}
