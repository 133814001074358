.previous-order-section-skeleton {
  position: absolute;
  top: 2.2rem;
  bottom: 0;
  width: calc(var(--container-width) - 15%);
  max-width: calc(var(--max-container-width) - 15%);
  overflow: hidden;
}

.previous-order-section-skeleton-content {
  height: 100%;
  overflow: auto;
  padding: 25px 20px;
  padding-bottom: 100px;
  background-color: var(--secondary-color);
  border-radius: 24px;
  overflow-y: hidden;
}

.previous-order-section-skeleton-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previous-order-section-skeleton-header div {
  border-radius: 5px;
  height: 0.625rem;
  margin-bottom: 0.5rem;
}

.previous-order-section-skeleton-header-section-one {
  width: 6.25rem;
}

.previous-order-section-skeleton-header-section-two {
  width: 5.125rem;
}
.previous-order-section-skeleton-header-section-three {
  width: 8.625rem;
}
.previous-order-section-skeleton-header-section-four {
  width: 4.875rem;
}
.previous-order-section-skeleton-header-section-five {
  width: 12.25rem;
}

.previous-order-section-skeleton-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.previous-order-section-skeleton-item-container {
  width: 100%;
  padding: 0.5rem;
}

.previous-order-section-skeleton-item-container div {
  border-radius: 5px;
}

.previous-order-section-skeleton-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.previous-order-section-skeleton-item-name {
  height: 0.875rem;
}

.previous-order-section-skeleton-item-name.large {
  width: 11.25rem;
}

.previous-order-section-skeleton-item-name.medium {
  width: 6.625rem;
}

.previous-order-section-skeleton-item-price {
  width: 3.25rem;
  height: 0.875rem;
}

.previous-order-section-skeleton-item-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
}

.previous-order-section-skeleton-item-detail {
  margin-bottom: 0.4rem;
  height: 0.625rem;
  margin-left: 1.3rem;
}

body[dir="rtl"] .previous-order-section-skeleton-item-detail {
  margin-left: unset;
  margin-right: 1.3rem;
}

.previous-order-section-skeleton-item-detail.small {
  width: 2.625rem;
}

.previous-order-section-skeleton-item-detail.medium {
  width: 5.625rem;
}

.previous-order-section-skeleton-item-detail.large {
  width: 8.125rem;
}

.previous-order-section-skeleton-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.previous-order-section-skeleton-bottom-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.previous-order-section-skeleton-bottom-row > div {
  border-radius: 5px;
  height: 0.8rem;
  margin-bottom: 10px;
}

.previous-order-section-skeleton-bottom-row.one .left {
  width: 3.25rem;
}

.previous-order-section-skeleton-bottom-row.one .right {
  width: 4.25rem;
}

.previous-order-section-skeleton-bottom-row.two .left {
  width: 6.5rem;
}

.previous-order-section-skeleton-bottom-row.two .right {
  width: 4rem;
}

.previous-order-section-skeleton-bottom-row.three .left {
  width: 9.625rem;
}

.previous-order-section-skeleton-bottom-row.three .right {
  width: 4rem;
}

.previous-order-section-skeleton-bottom-row.four .left {
  width: 2.375rem;
}

.previous-order-section-skeleton-bottom-row.four .right {
  width: 4.3rem;
}

.previous-order-section-skeleton-btns-container {
  border-radius: 8px;
  bottom: 2rem;
  left: 11%;
  right: 11%;
  height: 42px;
  position: fixed;
  width:75%;
  max-width: calc(var(--max-container-width) - 25%);
  margin:0 auto;
}

@media screen and (min-width: 940px) {
.previous-order-section-skeleton-btns-container {
    left: 10%;
    right: 10%;
  }
}

